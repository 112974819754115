<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div v-if="!newView">
        <div
          v-if="selectedViewMain === '1'"
          key="1"
          class="pa-5"
        >
          <v-card elevation="0" rounded="xl">
            <v-btn
              class="mt-3 ml-3"
              color="green"
              dark
              @click="newView = !newView"
            >
              New view
            </v-btn>
            <v-card-title>
              <div class="mr-5">
                Active gateway
              </div>
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="40"
                />
              </template>
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="gateways"
                :search="search"
                :footer-props="footerProps"
                :loading="showProgress"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.lastConnected="{ item }">
                  <v-chip
                    v-if="active(item.lastConnected) === true"
                    dark
                    color="green"
                  >
                    Active {{ dayjs.unix(item.lastConnected / 1000).format('HH:mm - DD/MM/YY') }}
                  </v-chip>
                  <v-chip
                    v-else
                    dark
                    color="red"
                  >
                    Inactive since {{ dayjs.unix(item.lastConnected / 1000).format('HH:mm - DD/MM/YY') }}
                  </v-chip>
                </template>
                <!--     <template v-slot:item.mainUserEmail="{ item }">
              {{ item.mainUserEmail }}
            </template>
            <template v-slot:item.typeI="{ item }">
              {{ deviceMapper(item.typeI) }}
            </template>
            <template v-slot:item.active="{ item }">
              <v-chip
                dark
                :color="item.active === true ? 'green' : 'red'"
              >
                {{ item.active ? 'ON-LINE' : 'OFF-LINE' }}
              </v-chip>
            </template>
            <template v-slot:item.landlordsDevice="{ item }">
              <v-icon
                v-if="item.landlordsDevice"
                large
                color="green darken-2"
              >
                mdi-check-circle
              </v-icon>
            </template> -->
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click="gatewayDetail(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card :color="selectedView === '1' ? 'red' : 'white'" class="mt-5">
            <v-card-title class="white--text">
              Gateway firmware update (OTA) <v-spacer />
              <v-btn
                v-if="selectedView === '1'"
                color="#F1C40F"
                @click="selectedView = '2'"
              >
                Upload new firmware
              </v-btn>
              <v-btn
                v-else
                dark
                color="green"
                @click="selectedView = '1'"
              >
                Back
              </v-btn>
            </v-card-title>
            <v-card-text
              v-if="selectedView === '1'"
              key="1"
            >
              <v-row>
                <v-col class="mb-4 pa-5 ma-3 white--text" style="background: #58D68D; border-radius: 10px;">
                  <div>
                    <span class="headline"><span style="font-size: 40px;">X3</span> firmware update</span> <span class="pl-5">(Will be scheduled for midnight)</span>
                  </div>
                  <v-select
                    v-model="selectedX3Firmware"
                    :items="x3Firmware"
                    label="Select X3 Firmware"
                  />
                  <v-btn
                    color="green"
                    :dark="selectedX3Firmware !== null ? true : false"
                    :disabled="!selectedX3Firmware"
                    small
                  >
                    Start
                  </v-btn>
                </v-col>
                <v-col class="mb-4 pa-5 ma-3  white--text" style="background: #3498DB; border-radius: 10px;">
                  <div>
                    <span class="headline"><span style="font-size: 40px;">X5</span> firmware update</span> <span class="pl-5">(Will be scheduled for midnight)</span>
                  </div>
                  <v-select
                    v-model="selectedX5Firmware"
                    style="color: white;"
                    :items="x5Firmware"
                    label="Select X5 Firmware"
                  />
                  <v-btn
                    color="green"
                    :dark="selectedX5Firmware !== null ? true : false"
                    small
                    :disabled="!selectedX5Firmware"
                  >
                    Start
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text
              v-if="selectedView === '2'"
              key="1"
            >
              <v-row>
                <v-col cols="6" class="mb-4 pa-5">
                  <v-select
                    v-model="firmwareForModel"
                    :items="availableModels"
                    label="Select Gateway model"
                    outlined
                  />
                  <v-text-field
                    v-model="firmwareVersion"
                    label="Firmware version"
                    outlined
                  />
                  <v-file-input
                    v-model="newFirmware"
                    accept=".bin"
                    label="Firmware file (.bin only!)"
                    outlined
                  />
                  <v-text-field
                    v-model="mdCheckSum"
                    label="Firmware checksum"
                    outlined
                  />
                  <v-btn
                    color="yellow"
                    :disabled="!firmwareForModel || !firmwareVersion || !newFirmware || !mdCheckSum || uploadLoading"
                    @click="uploadFirmware"
                  >
                    <div v-if="!uploadLoading">
                      Upload
                    </div>
                    <div v-else>
                      <v-progress-circular
                        indeterminate
                        color="white"
                      />
                    </div>
                  </v-btn>
                </v-col>
                <v-col class="text-center">
                  <div class="mt-5">
                    <v-icon style="font-size: 90px;" color="red">
                      mdi-alert
                    </v-icon>
                  </div>
                  <div class="mt-5 headline">
                    File has to be .bin, you need checksum from owon. Please make sure the firmware is tested and verified! there is no return back!<br>
                  </div>
                </v-col>
              </v-row>
              <v-alert
                v-if="message"
                style="position: absolute; top: 25px;; left: 50%; transform: translate(-50%);"
                type="success"
                dismissible
              >
                {{ message }}
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-if="selectedViewMain === '2'"
          key="2"
          class="pa-5"
        >
          <v-card>
            <v-card-title>
              <div class="headline">
                Gateway detail
              </div>
              <v-spacer />
              <v-btn
                dark
                color="green"
                @click="selectedViewMain = '1'; viewingGateway = {}; heartBeatGateway = [];"
              >
                Back
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <div>Gateway type:</div>
                  <div>Gateway Firmware:</div>
                  <div>Last check-in:</div>
                  <div>Main user:</div>
                  <div>Agent id:</div>
                  <div>App token:</div>
                  <div>Disconnections past 30days:</div>
                  <div>Last update status:</div>
                </v-col>
                <v-col cols="9" style="font-weight: bolder;">
                  <div>{{ viewingGateway.devModel }}</div>
                  <div>{{ viewingGateway.softwareVer }}</div>
                  <div>
                    {{ dayjs.unix(viewingGateway.lastConnected/1000).format('DD/MM/YYYY HH:mm') }}
                    <v-chip
                      v-if="active(viewingGateway.lastConnected) === true"
                      x-small
                      dark
                      color="green"
                    >
                      Active
                    </v-chip>
                    <v-chip
                      v-else
                      x-small
                      dark
                      color="red"
                    >
                      Inactive!
                    </v-chip>
                  </div>
                  <div class="blue--text">
                    {{ viewingGateway.mainUserEmail }}
                  </div>
                  <div>{{ viewingGateway.agentId }}</div>
                  <div>{{ viewingGateway.appToken }}</div>
                  <div class="red--text">
                    {{ viewingGateway.disconnections }}
                  </div>
                  <div class="red--text">
                    {{ viewingGateway.lastUpdateStatus }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-5">
            <v-card-text v-if="viewingGateway.devModel.includes('X5')">
              <div class="headline">
                <span class="red--text">!!STOP & CHECK!! <span style="font-weight: bold;">GW MODEL: {{ viewingGateway.devModel }}</span></span>
              </div>
              <v-row v-if="!updatingFirmware">
                <v-col class="mt-4 pa-5 ma-3  white--text" style="background: #3498DB; border-radius: 10px;">
                  <div>
                    <span class="headline"><span style="font-size: 40px;">X5</span> firmware update</span> <span class="pl-5" />
                  </div>
                  <v-select
                    v-model="manualFirmwareUpdateVersion"
                    style="color: white;"
                    :items="x5Firmware"
                    label="Select X5 Firmware"
                  />
                  <v-text-field
                    v-model="firmwareVersionManual"
                    label="Firmware Version (104)"
                    type="number"
                  />
                  <v-btn
                    color="green"
                    :dark="manualFirmwareUpdateVersion !== null ? true : false"
                    small
                    :disabled="!manualFirmwareUpdateVersion"
                    @click="manualFirmwareUpdate"
                  >
                    Start
                  </v-btn>
                </v-col>
              </v-row>
              <div v-else style="height: 200px;">
                <div style="position: relative; top: 50%; left: 50%; transform: translate(-50%, 50%); width: 450px;">
                  <v-progress-circular
                    indeterminate
                    color="green"
                  />
                  <span class="pl-5">Uploading new firmware, this may take up to 15 minutes</span>
                </div>
              </div>
            </v-card-text>
            <v-card-text v-else-if="viewingGateway.devModel.includes('X3')">
              <div class="headline">
                <span class="red--text">!!STOP & CHECK!! <span style="font-weight: bold;">GW MODEL: {{ viewingGateway.devModel }}</span></span>
              </div>
              <v-row v-if="!updatingFirmware">
                <v-col class="mt-4 pa-5 ma-3 white--text" style="background: #58D68D; border-radius: 10px;">
                  <div>
                    <span class="headline"><span style="font-size: 40px;">X3</span> firmware update</span> <span class="pl-5" />
                  </div>
                  <v-select
                    v-model="manualFirmwareUpdateVersion"
                    :items="x3Firmware"
                    label="Select X3 Firmware"
                  />
                  <v-text-field v-model="firmwareVersionManual" type="number" />
                  <v-btn
                    color="green"
                    :dark="manualFirmwareUpdateVersion !== null ? true : false"
                    :disabled="!manualFirmwareUpdateVersion"
                    small
                    @click="manualFirmwareUpdate"
                  >
                    Start
                  </v-btn>
                </v-col>
              </v-row>
              <div v-else style="height: 200px;">
                <div style="position: relative; top: 50%; left: 50%; transform: translate(-50%, 50%); width: 450px;">
                  <v-progress-circular
                    indeterminate
                    color="green"
                  />
                  <span class="pl-5">Uploading new firmware, this may take up to 15 minutes</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mt-3">
            <v-card-title>Heartbeat for Gateway</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersHeartbeat"
                :items="heartBeatGateway"
                :search="search2"
                :footer-props="footerProps2"
                :loading="showProgress"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.timeReceived="{ item }">
                  {{ dayjs(item.timeReceived).format('HH:mm:ss:SSS') }}
                </template>
                <template v-slot:item.timeResponded="{ item }">
                  {{ dayjs(item.timeResponded).format('HH:mm:ss:SSS') }} {{ item.timeResponded }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          {{ viewingGateway.lastUpdateStatus }}
        </div>
      </div>
      <div v-else>
        <v-btn class="pa-3" @click="newView = !newView">
          Old view
        </v-btn>
        <div class="pa-3 mt-5">
          <div class="text-h5">
            Gateways
            <hr>
          </div>
          <v-row class="mt-2">
            <div class="pa-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs}">
                  <v-chip
                    dark
                    color="green"
                    v-bind="attrs"
                    @click="filter = ''"
                    v-on="on"
                  >
                    Gateways - <span class="font-weight-bold pl-1">{{ gateways.length }}</span>
                  </v-chip>
                </template>
                <span>Remove filters</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs}">
                  <v-chip
                    class="ml-1"
                    dark
                    color="green"
                    v-bind="attrs"
                    @click="filter = 'active'"
                    v-on="on"
                  >
                    Online - <span class="font-weight-bold pl-1">{{ activeGateways.active }}</span>
                  </v-chip>
                </template>
                <span>Filter Online</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs}">
                  <v-chip
                    class="ml-1"
                    dark
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                    @click="filter = 'inactive'"
                  >
                    Offline - <span class="font-weight-bold pl-1">{{ activeGateways.inactive }}</span>
                  </v-chip>
                </template>
                <span>Filter Offline</span>
              </v-tooltip>
            </div>
          </v-row>
          <div class="mt-3">
            Building <hr>
          </div>
          <v-row class="mt-2">
            <v-chip
              v-for="(building, i) in buildings"
              :key="i"
              color="blue"
              class="ma-1"
              dark
              @click="filter = building"
            >
              {{ building === 'N/A' ? 'Home Owners' : building }}
            </v-chip>
          </v-row>
          <div class="mt-4">
            City
            <hr>
          </div>
          <v-row class="mt-2">
            <v-chip
              v-for="(city, i) in cities"
              :key="i"
              color="purple"
              dark
              class="ma-1"
              @click="filter = city"
            >
              {{ city || 'N/A' }}
            </v-chip>
          </v-row>
        </div>
        <v-container fluid>
          <v-row dense>
            <v-col
              v-for="gateway in filteredGateways"
              :key="gateway.id"
              md="4"
            >
              <v-card
                class="ma-auto mb-3"
                width="100%"
                height="100%"
                outlined
                rounded
                :style="active(gateway.lastConnected) ? 'border: 1px solid green;' : 'border: 1px solid #e46161; opacity: 0.6; background-color: #ff7e673b;'"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      {{ gateway.devModel }} <span style="font-size: 10px;">{{ gateway.chiptype || 'N/A' }} <br> {{ cropIfLong(gateway.softwareVer) || 'N/A' }}</span>
                    </div>
                    <v-list-item-title class="text-h6 mb-1" :class="gateway.buildingId !== 'N/A' ? 'green--text' : 'blue--text'">
                      {{ gateway.buildingId !== 'N/A' ? gateway.buildingId : 'Home Owner' }}<br>
                      {{ gateway.propertyName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ gateway.city || 'No city' }} <br>
                      {{ gateway.mainUserEmail }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="80"
                    color="transparent"
                  >
                    <v-img
                      v-if="gateway.devModel.includes('X3')"
                      contain
                      src="@/assets/gateway.png"
                    />
                    <v-img
                      v-else-if="gateway.devModel.includes('X5')"
                      contain
                      src="@/assets/gateway2.png"
                    />
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    text
                  >
                    Button
                  </v-btn>
                  <v-spacer />
                  <div>
                    <v-chip dark :color="gateway.disconnections < 1 ? '#42b883' : '#e46161'">
                      {{ gateway.disconnections }}
                    </v-chip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-icon
                          v-if="active(gateway.lastConnected)"
                          style="font-size: 38px;"
                          color="#42b883"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-check-circle
                        </v-icon>
                        <v-icon
                          v-else
                          color="#e46161"
                          style="font-size: 38px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>Last seen: {{ dayjs.unix(gateway.lastConnected / 1000) }}</span>
                    </v-tooltip>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'AllDevices',
  components: {
  },
  data () {
    return {
      dayjs,
      filter: '',
      newView: false,
      selectedViewMain: '1',
      selectedView: '1',
      showProgress: false,
      updatingFirmware: false,
      firmwareUpdated: false,
      gateways: [],
      search: '',
      search2: '',
      heartBeatGateway: [],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Main user', value: 'mainUserEmail' },
        { text: 'SocketId', value: 'gatewaySocketId' },
        { text: 'MAC', value: 'mac' },
        // { text: 'appToken', value: 'appToken' },
        { text: 'Firmware', value: 'softwareVer' },
        { text: 'Model', value: 'devModel' },
        { text: 'Last check-in', value: 'lastConnected' },
        { text: 'Disconnections', value: 'disconnections' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Property Id', value: 'propertyId' },
        { text: 'Last UP Stat', value: 'lastUpdateStatus' }
        // { text: 'CreatedAt', value: 'createdAt' }
      ],
      headersHeartbeat: [
        { text: '#', value: 'id' },
        { text: 'Type', value: 'commandType' },
        { text: 'Received', value: 'timeReceived' },
        { text: 'Responded', value: 'timeResponded' }
      ],
      footerProps: {
        'items-per-page-options': [50, 80, 100]
      },

      footerProps2: {
        'items-per-page-options': [10, 80, 100]
      },
      isMounted: false,
      x3Firmware: ['1', '2'],
      x5Firmware: ['1', '2'],
      availableFirmwares: [],
      selectedX5Firmware: null,
      selectedX3Firmware: null,
      firmwareForModel: null,
      availableModels: ['SEG-X3', 'SEG-X5'],
      firmwareVersion: null,
      newFirmware: null,
      mdCheckSum: null,
      uploadLoading: false,
      message: '',
      viewingGateway: {},
      manualFirmwareUpdateVersion: null,
      firmwareVersionManual: 107,
      firmwareType: 'rootfs'
    }
  },
  computed: {
    activeGateways () {
      const active = this.gateways.filter(item => this.active(item.lastConnected) === true).length
      const inactive = this.gateways.filter(item => this.active(item.lastConnected) === false).length
      return { active, inactive }
    },
    buildings () {
      const cities = this.gateways.map(item => item.buildingId)
      return [...new Set(cities)]
    },
    cities () {
      const cities = this.gateways.map(item => item.city)
      return [...new Set(cities)]
    },
    filteredGateways () {
      let filtered
      const items = this.gateways
      if (this.filter !== 'active' || this.filter !== 'inactive') {
        filtered = items
      }
      if (this.filter === 'active') {
        filtered = items.filter(item => this.active(item.lastConnected) === true)
      }
      if (this.filter === 'inactive') {
        filtered = items.filter(item => this.active(item.lastConnected) === false)
      }
      return filtered
    }
  },
  watch: {
  },
  mounted () {
    this.pullAllActiveGateways()
    this.isMounted = true
  },
  methods: {
    pullAllActiveGateways () {
      this.showProgress = true
      this.$store.dispatch('getAllActiveGateways')
        .then(resp => {
          if (resp) {
            this.gateways = resp.data
            this.showProgress = true
            this.$store.dispatch('getAllFirmwares').then(resp => {
              this.availableFirmwares = resp.data

              const X3 = this.availableFirmwares.filter(item => item.gatewayType === 'SEG-X3')
              const X5 = this.availableFirmwares.filter(item => item.gatewayType === 'SEG-X5')

              this.x3Firmware = X3.map(item => item.firmwareVersion)
              this.x5Firmware = X5.map(item => item.firmwareVersion)
            })
          }
          this.showProgress = false
        })
    },
    active (time) {
      const timeSec = Math.floor(time / 1000)
      const now = dayjs().subtract(4, 'minute')
      const date = dayjs.unix(timeSec)
      if (dayjs(date).isBefore(now)) {
        return false
      } else {
        return true
      }
    },
    uploadFirmware () {
      this.uploadLoading = true
      const fileData = new FormData()

      fileData.append('files', this.newFirmware)

      this.$store.dispatch('uploadFirmwareFile', fileData)
        .then((resp) => {
          this.newFirmware = null
          this.saveNewFirmware(resp.data)
        })
        .catch((error) => {
          this.uploadLoading = false
          this.uploadError = true
          this.error = error
        })
    },
    saveNewFirmware (resp) {
      const location = resp[0].Location
      this.$store.dispatch('uploadFirmware', { firmwareForModel: this.firmwareForModel, firmwareVersion: this.firmwareVersion, mdCheckSum: this.mdCheckSum, location })
        .then(() => {
          this.clearForm()
          this.pullAllActiveGateways()
        })
    },
    clearForm () {
      this.uploadLoading = false
      this.firmwareForModel = null
      this.firmwareVersion = null
      this.mdCheckSum = null
      this.message = 'Firmware uploaded successfully'
    },
    gatewayDetail (item) {
      this.$store.dispatch('getHeartBeatForGateway', { propertyId: item.propertyId }).then(resp => {
        this.heartBeatGateway = resp.data
        this.viewingGateway = item
        this.selectedViewMain = '2'
      })
    },
    manualFirmwareUpdate () {
      this.updatingFirmware = true
      let type = 'SEG-X3'
      if (this.viewingGateway.devModel.includes('X5')) {
        type = 'SEG-X5'
      }
      const data = {
        type: type,
        propertyId: this.viewingGateway.propertyId,
        firmwareVersion: this.manualFirmwareUpdateVersion,
        version: this.firmwareVersionManual,
        firmwareType: this.firmwareType
      }
      this.$store.dispatch('manualFirmwareUpdate', data)
        .then(() => {
          setTimeout(() => {
            this.updatingFirmware = false
            this.firmwareUpdated = true
          }, 600000)
        })
    },
    cropIfLong (text) {
      if (text.length > 30) {
        return text.substring(0, 30) + '...'
      } else {
        return text
      }
    }
  }
}
</script>

<style scoped>
.some {
  color: #ff7e673b;
}
</style>
